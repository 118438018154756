<template>
  <div class="infoNavigation">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <!-- 信息认证导航 -->
      <div class="box">
        <van-cell
          @click="toauthFace"
          title="活体认证"
          :value-class="faceSuccess ? 'blueText' : ''"
          is-link
          :value="faceSuccess ? '已认证' : '未认证'"
        />
        <van-cell
          v-if="baseSuccess === -1"
          @click="baseInfo1"
          title="基础信息"
          is-link
          value-class="blueText"
          value="可更改"
        />
        <van-cell
          v-else
          @click="baseInfo"
          :value-class="baseSuccess ? 'blueText' : ''"
          title="基础信息"
          is-link
          :value="baseSuccess ? '已填写' : '未填写'"
        />
        <!-- <van-cell
          v-if="uploadSuccess === -1"
          @click="uploadImg1"
          title="授信附件资料"
          is-link
          value-class="blueText"
          value="可更改"
        />
        <van-cell
          v-else
          @click="uploadImg"
          title="授信附件资料"
          is-link
          :value-class="uploadSuccess ? 'blueText' : ''"
          :value="uploadSuccess ? '已上传' : '未上传'"
        /> -->

        <div @click="subFile" class="subBtn">
          提交
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<style>
.box .blueText {
  color: #8486f8;
}
</style>
<script>
import { toRefs, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Jump } from '@/utils/routh'
import { routerTag, GetUrlParams } from '../../utils/index'
import API from '../../server/api'
import { Toast } from 'vant'
export default {
  setup () {
     // 设置标题Title
    // const Route = useRoute()
    // const Router = useRouter()
    const state = reactive({
      creditState: -1,
      baseSuccess: null, // 基础信息填写
      // loanSuccess: null, // 借款意向填写
      faceSuccess: null, // 活体认证填写
      uploadSuccess: null, // 授权资料
      fourBool: null,
      preCreditNo: '',
      isLoading: false,
      deviceData: {},
      key: null,
      userID:''
    })
    // const router = useRouter()
    // 下拉刷新
    const onRefresh = () => {
      state.isLoading = false
      getStatus(state.userID)
    }
    // 跳转到人脸认证
    const toauthFace = () => {
      // eslint-disable-next-line eqeqeq
      if (state.faceSuccess === 1) {
        Toast.fail('不能重复认证哦！')
        return false
      } else {
        Jump(`${routerTag}authFace`)
      }
    }
    const baseInfo1 = () => {
      Jump(`${routerTag}baseInfo`)
    }
    const baseInfo = () => {
      if (state.baseSuccess ===1) {
        // 修改
        //  Toast.fail('不能重复提交哦！')
        // console.log('75')
        // return false
        Jump(`${routerTag}baseInfo`)
      } else {
        // 新增
        // console.log('76')
        // return false
        // APP.JUMP_TO(`${routerTag}baseInfo?key=` + 0)
        Jump(`${routerTag}baseInfo`)
      }
    }
    const uploadImg1 = () => {
      Jump(`${routerTag}supplementImg`)
    }
    const uploadImg = () => {
      if (state.uploadSuccess === 1) {
        // 补充资料、提额资料，如果提交过一次图片，他就又变成1了，
        // 这里如果是1 的情况下，再判断下状态
        // Toast.fail('不能重复提交哦！')
        let u = `${routerTag}uploadImg?key=${1}`
        if (state.creditState > 2) u = `${routerTag}supplementImg?key=${1}`
        Jump(u)
      } else {
        // 普通授信
        Jump(`${routerTag}uploadImg?key=${1}`)
      }
    }
    const getStatus = async () => {
      const result = await API.getUserStatus({})
      console.log(result,'result授信')
      state.faceSuccess = result[0].processState
      // state.loanSuccess = result[1].processState
      state.baseSuccess = result[1].processState
      state.uploadSuccess = result[2].processState

      /**
       *  0 还没有填写
       *  1 已经填写了，不能修改
       *  -1 可以修改
       *  这里来判断下面的按钮是否可以点击，进行提交
       * */

      let bool = true
      if (Array.isArray(result)) {
        result.forEach(item => {
          if (item.processState === 0) {
            bool = false
          }
        })
      }
      state.fourBool = bool

      //  这里是冗余代码，为了根据当前用户的状态来判断可以编辑的内容
      try {
        const phone = localStorage.getItem('cellPhone')
        const res = await API.isUser({ phone,channel:'BLMX_H5' })
        state.creditState = res.creditState
      } catch (e) {
        console.log(e, '报错')
      }

    }
    // 提交
    const subFile = () => {
      // router.back()
      // console.log('返回了啊')
      // return false
      if (!state.faceSuccess) {
        Toast.fail('请先进行人脸认证!')
        return
      }
      if (!state.baseSuccess) {
        Toast.fail('请先填写基础信息!')
        return
      }
      
      // eslint-disable-next-line eqeqeq
      if (state.key == 2) {
        console.log(state.key, '9999')
        API.subAddMoney()
        Toast.success('已提交')
        setTimeout(function () {
          APP.Back(1)
        }, 1000)
      } else {
        console.log(state.key, '888')
        API.gotoLoan({
          preCreditNo: state.preCreditNo,
          jsonObject: state.deviceData
        })
        Toast.success('已提交')
        setTimeout(function () {
         APP.Back(1)
        }, 1000)
      }
    }

    // 获取公用数据
    const getCommonData = async () => {
      await APP.COMMONDATA().then((res) => {
        console.log(res, '公用数据')
        state.deviceData = res
      })
    }

    onMounted(() => {
      // console.log(GetUrlParams(window.location.href),'地址');
      state.key = GetUrlParams(window.location.href).key
      // state.key=localStorage.getItem('keyId')
      console.log(state.key,'地址')
      state.preCreditNo = localStorage.getItem('preCreditNo')
      state.userID=localStorage.getItem("userId");
      getStatus(state.userID)
      getCommonData()
    })
    return {
      ...toRefs(state),
      toauthFace,
      baseInfo,
      baseInfo1, // 可更改
      uploadImg,
      uploadImg1,
      subFile,
      onRefresh
    }
  }
}
</script>
